import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from './Controls.module.css';

const Controls = () => {
  const {
    clearFilter,
    app: { i18n },
    ui,
    filter: { isDefault, getSavedFilterByHash },
    savedFilters,
  } = useStore();

  const savedFilterByHash = getSavedFilterByHash();

  const handleSaveAsFilter = async () => {
    const isLoggedIn = window.atiUser?.is_user;
    if (isLoggedIn) {
      await savedFilters.openFilterPopup();
    } else {
      ui.openLoginPopup();
    }
  };

  return (
    <div className={css.container}>
      {!isDefault && (
        <button
          className={cn(css.button, 'glz-link', ' glz-is-medium', 'glz-is-gray', 'glz-is-dashed')}
          role="presentation"
          onClick={clearFilter}
        >
          {i18n.app.clearForm}
        </button>
      )}

      {savedFilters.canSaveFilter &&
        (savedFilterByHash ? (
          <button
            className={cn(css.button, 'glz-link glz-is-medium glz-is-bold glz-is-dashed')}
            onClick={async () => await savedFilters.openFilterPopup(savedFilterByHash?.savedFilter)}
          >
            <Icon className="glz-before-icon" color="currentColor" height={15} name="ic_check-bold" width={15} />
            {i18n.app.filterSaved}
          </button>
        ) : (
          <button
            className={cn(
              css.button,
              'glz-link',
              'glz-is-medium',
              'glz-is-primary',
              'glz-is-dashed',
              'glz-tooltip',
              'glz-is-position-top',
            )}
            onClick={handleSaveAsFilter}
          >
            {i18n.app.saveAsFilter}
          </button>
        ))}
    </div>
  );
};

const connected = observer(Controls);

export { connected as Controls };
