import Icon from '@components/icon';
import { observer } from 'mobx-react-lite';

import { searchInstruction, searchRutubeGuide } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import css from './Instructions.module.css';

const Instructions = () => {
  const {
    app: { i18n, env },
  } = useStore();

  return (
    <div className={css.container}>
      <div className={css.linkContainer}>
        <Icon className={css.icon} height={10} name="ic_rutube" width={12} />

        <a
          className="glz-link glz-is-medium glz-is-gray"
          href={searchRutubeGuide}
          rel="noopener noreferrer"
          target="_blank"
        >
          {i18n.app.howToSearchOnATI}
        </a>
      </div>

      <div className={css.linkContainer}>
        <Icon className={css.icon} color="var(--glz-color-primary)" height={12} name="ic_doc" width={12} />

        <a
          className="glz-link glz-is-medium glz-is-gray"
          href={interpolate(searchInstruction, { env })}
          rel="noopener noreferrer"
          target="_blank"
          title={`${i18n.app.searchInstruction.firstPart} ${i18n.app.searchInstruction.secondPart}`}
        >
          {i18n.app.searchInstruction.firstPart}{' '}
          <span className={css.instructionSecondPart}>{i18n.app.searchInstruction.secondPart}</span>
        </a>
      </div>
    </div>
  );
};

const connected = observer(Instructions);

export { connected as Instructions };
