import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import styles from './Route.module.css';

const Route = () => {
  const {
    app: { i18n },
    savedFilters: { popup },
  } = useStore();

  const from = popup?.summary.from;
  const to = popup?.summary.to;

  return (
    <div className={styles.main}>
      {from && (
        <p className={styles.cityName}>
          {!to && i18n.common.routeFrom} {from} {to && '→'}
        </p>
      )}

      {to && (
        <p className={styles.cityName}>
          {!from && i18n.common.routeTo} {to}
        </p>
      )}
    </div>
  );
};

const connected = observer(Route);

export { connected as Route };
