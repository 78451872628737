import { useStore } from 'hooks/useStore';

import css from './Cargo.module.css';

const Weight = ({ from, to }: { from?: number | null; to?: number | null }) => {
  const {
    app: { i18n },
  } = useStore();

  if (!from && !to) return null;

  let value = `${from}${from && to && '-'}${to}`;

  if (from && !to) {
    value = `${i18n.common.from} ${from}`;
  }

  if (!from && to) {
    value = `${i18n.common.to} ${to}`;
  }

  return (
    <>
      <span className={css.value}>{value}</span> {i18n.common.tons}
    </>
  );
};

const Volume = ({ from, to }: { from?: number | null; to?: number | null }) => {
  const {
    app: { i18n },
  } = useStore();

  if (!from && !to) return null;

  let value = `${from}${from && to && '-'}${to}`;

  if (from && !to) {
    value = `${i18n.common.from} ${from}`;
  }

  if (!from && to) {
    value = `${i18n.common.to} ${to}`;
  }

  return (
    <>
      <span className={css.value}>{value}</span> {i18n.common.meter}
      <sup>3</sup>
    </>
  );
};

const LoadType = ({ dogruz, loadType }: { dogruz?: null | boolean; loadType: string }) => {
  if (typeof dogruz === 'boolean') return <span>{loadType}</span>;

  return null;
};

const Enumeration = ({ data }: { data: string }) => {
  const {
    app: { i18n },
  } = useStore();
  if (!data) return null;

  let text = '';
  const needToCutDown = data.split(',').length > 3;

  if (needToCutDown) {
    text = `${data.split(', ').slice(0, 3).join(', ')} ${i18n.filterPopup.summary.etc}`;
  } else {
    text = data;
  }

  return <span>{text}</span>;
};

const Delimeter = ({ isVisible }: { isVisible: boolean }) => {
  if (!isVisible) return null;

  return <>, </>;
};

const Cargo = () => {
  const {
    savedFilters: { popup },
  } = useStore();

  if (!popup) return null;

  return (
    <div>
      <p className={css.section}>
        <Weight from={popup.filter?.weight?.min} to={popup.filter?.weight?.max} />
        <Delimeter isVisible={Boolean(popup.filter?.weight?.min || popup.filter?.weight?.max)} />

        <Volume from={popup.filter?.volume?.min} to={popup.filter?.volume?.max} />
        <Delimeter isVisible={Boolean(popup.filter?.volume?.min || popup.filter?.volume?.max)} />

        <LoadType dogruz={popup.filter?.dogruz} loadType={popup.summary.loadType} />
        <Delimeter isVisible={typeof popup.filter?.dogruz === 'boolean'} />

        <Enumeration data={popup.summary.truckType} />
        <Delimeter isVisible={Boolean(popup.summary.truckType)} />

        <span>{popup.summary.withAdr}</span>
        <Delimeter isVisible={Boolean(popup.summary.withAdr)} />

        <span className={css.value}>{popup.summary.loadDate.toLowerCase()}</span>

        <Delimeter isVisible={Boolean(popup.summary.cargoTypes)} />
        <Enumeration data={popup.summary.cargoTypes} />
      </p>
    </div>
  );
};

export { Cargo };
