import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { Fragment, useState } from 'react';

import { useStore } from 'hooks/useStore';
import { capitalize } from 'utils/string/format';

import css from './DetailedSummary.module.css';

const DetailedSummary = () => {
  const {
    app: { i18n },
    savedFilters: { popup },
  } = useStore();

  const initialIsOpened = !popup?.summary.showCompactSummary;
  const [isOpened, setIsOpened] = useState(initialIsOpened);

  if (!popup) return null;

  return (
    <>
      {popup.summary.showCompactSummary && (
        <button
          className={cn(css.toggle, 'glz-link glz-is-medium glz-is-primary')}
          onClick={() => setIsOpened(!isOpened)}
        >
          {isOpened ? i18n.filterPopup.summary.hide : i18n.filterPopup.summary.show}
        </button>
      )}

      {isOpened && (
        <div className={css.details}>
          {popup.summaryItems.map(item => (
            <Fragment key={item.type}>
              <div className={css.type}>{capitalize(item.type)}:</div>
              <div className={css.value}>{item.value}</div>
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
};

const connected = observer(DetailedSummary);

export { connected as DetailedSummary };
