import Popover from '@components/popover';
import Skeleton from '@components/skeleton';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { MetricsEvent, events } from 'constants/metrics';
import { useStore } from 'hooks/useStore';
import { sendMetricsEvent } from 'utils/metrics';
import { interpolate } from 'utils/string/format';

import { PromoIcon } from './PromoIcon';

import css from './SubscribeButton.module.css';

export type TSubscribeButtonProps = {
  className?: string;
  withDescription?: boolean;
  placement: 'searchResults' | 'pinned';
};

const PLACEMENT_TO_METRIC: { [key in TSubscribeButtonProps['placement']]: MetricsEvent } = {
  searchResults: events.searchResultsSubscribeButtonClick,
  pinned: events.pinnedSubscribeButtonClick,
};

const SubscribeButton = ({ className, withDescription, placement }: TSubscribeButtonProps) => {
  const {
    app,
    savedFilters: { canSaveFilter, openFilterPopup },
    filter: {
      getSavedFilterByHash,
      isSubscriptionPromoVisible,
      isSubscriptionTrialInfoVisible,
      hideSubscriptionPromo,
      trialDaysDuration,
      isSubscriptionTrialInfoLoading,
    },
  } = useStore();

  if (canSaveFilter === false) return null;

  const savedFilter = getSavedFilterByHash();

  const handleClosePopover = () => {
    hideSubscriptionPromo();
  };

  const handleSubscription = () => {
    handleClosePopover();
    sendMetricsEvent(PLACEMENT_TO_METRIC[placement]);
    openFilterPopup(savedFilter?.savedFilter, { enableSubscription: true, openedFrom: placement });
  };

  const SubscriptionPromoTrialText = () => {
    if (isSubscriptionTrialInfoLoading) {
      return <Skeleton className={css.promoTextFree} height={20} width={125} />;
    }

    if (isSubscriptionTrialInfoVisible === false) return null;

    return (
      <p className={cn('glz-p glz-is-size-4', css.promoTextFree)}>
        {interpolate(app.i18n.subscriptionPromoHint, { days: trialDaysDuration.toString() })}
      </p>
    );
  };

  return (
    <div className={cn(css.container, className)}>
      {savedFilter?.hasSubscription ? (
        <>
          <button
            className={cn(css.button, 'glz-button glz-is-small glz-is-primary glz-is-success')}
            onClick={handleSubscription}
          >
            {app.i18n.alreadySubscribed.button}
          </button>
          {withDescription && <span className={css.text}>{app.i18n.alreadySubscribed.desc}</span>}
        </>
      ) : (
        <>
          <Popover
            className={css.popover}
            classNames={{
              contentWrapper: css.contentWrapper,
              closeButton: css.closeButton,
            }}
            content={
              <Popover.Content className={css.content}>
                <div>
                  <PromoIcon />
                </div>

                <div className={css.promoTextContainer}>
                  <p className={cn('glz-p glz-is-size-3', css.promoTextSubscribe)}>{app.i18n.subscriptionPromoTitle}</p>
                  <SubscriptionPromoTrialText />
                </div>
              </Popover.Content>
            }
            isAdaptive={false}
            visible={isSubscriptionPromoVisible}
            onCloseClick={handleClosePopover}
            onVisibilityChange={handleClosePopover}
          >
            <button
              className={cn(css.button, 'glz-button glz-is-small glz-is-success glz-is-outlined')}
              onClick={handleSubscription}
            >
              {app.i18n.subscribe.button}
            </button>
          </Popover>
          {withDescription && <span className={css.text}>{app.i18n.subscribe.desc}</span>}
        </>
      )}
    </div>
  );
};

const connected = observer(SubscribeButton);

export { connected as SubscribeButton };
